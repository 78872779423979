<script lang="ts" setup>
import AppContent from '@/components/app-content.vue'
import { useIntervalFn } from '@vueuse/core'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useHeadTitle } from '../../src/lib/use-app-head-holtwick'
import heroImage from './_assets/mock.jpg?url'

import './app-homepage.styl'

const { locale, t } = useI18n()

const next = ref(0)

useIntervalFn(() => next.value = next.value >= 2 ? 0 : next.value + 1, 5e3)

useHeadTitle(t('homepage.title'))
</script>

<template>
  <main class="app-homepage">
    <div class="hero">
      <div class="grid-container">
        <div class="content">
          <div class="content-text">
            <div class="headlines">
              <h1 class="clip">
                {{ $t("homepage.title") }}
              </h1>
              <p class="slogan">
                {{ $t("homepage.slogan") }}
              </p>
            </div>
            <div class="actions">
              <a class="button" href="/latest">
                <Rocket />
                {{ $t("homepage.download") }}</a>
              <!-- <a class="button" href="/latest">{{ $t("homepage.download") }}</a> -->
              <!-- <router-link :to="`/${$i18n.locale}/help`" >
                {{ $t("homepage.buttonMore") }}
              </router-link> -->
              <!-- <router-link :to="`/${$i18n.locale}/buy`" class="button">
                Buy now
              </router-link> -->
            </div>
            <div class="configuration">
              {{ $t("homepage.requirements") }}
            </div>
          </div>
          <div class="content-show">
            <img :src="heroImage" alt="Illustration">
          </div>
        </div>
      </div>
    </div>

    <div class="app-content prose">
      <Suspense>
        <AppContent :component="$route.meta.comp" />
      </Suspense>
    </div>
  </main>
</template>
